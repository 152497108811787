import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/suspect/Documents/coding/dbs-dresden/src/componets/mdxTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <title>Impressum</title>
    <h1>{`Impressum`}</h1>
    <ul>
      <li parentName="ul">{`dbs dresden consult Dr. Thomas Blume unabhängiger Finanz- und Versicherungsmakler`}</li>
      <li parentName="ul">{`Bautzner Str. 75 01099 Dresden`}</li>
      <li parentName="ul">{`Tel.: 0351/8108704`}</li>
      <li parentName="ul">{`Fax: 0351/8108705`}</li>
      <li parentName="ul">{`Mobil: 0179/2791915`}</li>
      <li parentName="ul">{`Email: `}<a parentName="li" {...{
          "href": "mailto:thomas.blume@dbs-dresden.de"
        }}>{`thomas.blume@dbs-dresden.de`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.dbs-dresden.de"
        }}>{`www.dbs-dresden.de`}</a></li>
      <li parentName="ul">{`Steuer-Nr.: 202/208/05833`}</li>
      <li parentName="ul">{`zuständiges Finanzamt: Finanzamt Dresden II`}</li>
      <li parentName="ul">{`zuständige Aufsichtsbehörden: für die Erlaubnis gemäß §34c,f GewO: Gewerbeamt Dresden, Theaterstraße 11-14, 01067 Dresden für die Erlaubnis gemäß §34d, Abs. 1 GewO sowie §34i GewO : IHK Dresden, Langer Weg 4, 01239 Dresden`}</li>
      <li parentName="ul">{`Vermögensschadenhaftpflicht: 1,83 Mio Euro Deckungssumme, Victoria HS-SV71404621.7 -00446`}</li>
      <li parentName="ul">{`Kontaktdaten des Registers: Deutscher Industrie- und Handelskammertag (DIHK) e.V., Breite Straße 29,10178 Berlin, `}<a parentName="li" {...{
          "href": "http://www.vermittlerregister.info"
        }}>{`www.vermittlerregister.info`}</a>{`, Telefon: 0-180-500 585-0, 14 Cent/Min. aus dem dt. Festnetz, höchstens 42 Cent/Min. aus Mobilfunknetzen`}</li>
      <li parentName="ul">{`Registriernummer: D-9JTW-44UW3-17 (§34d, Abs. 1 GewO), D-F-144-A11T-17 (§34f GewO), D-W-144-11GJ-83 (§34i GewO) Beschwerdestellen: Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin) Graurheindorfer Str. 108, 53117 Bonn, `}<a parentName="li" {...{
          "href": "http://www.bafin.de"
        }}>{`www.bafin.de`}</a></li>
      <li parentName="ul">{`Schlichtungsstellen: `}<a parentName="li" {...{
          "href": "http://www.versicherungsombudsmann.de"
        }}>{`www.versicherungsombudsmann.de`}</a>{`; `}<a parentName="li" {...{
          "href": "http://www.pkv-ombudsmann.de"
        }}>{`www.pkv-ombudsmann.de`}</a></li>
    </ul>
    <p>{`Der Makler hält keine unmittelbare oder mittelbare Beteiligung von mehr als 10% der Stimmrechte oder des Kapitals an einem Versicherungsunternehmen. Ein Versicherungsunternehmen hält keine unmittelbare oder mittelbare Beteiligung von mehr als 10% der Stimmrechte oder des Kapitals am Versicherungsmakler.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      